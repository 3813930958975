<template>
  <page-layout>
    <div id="buyCart">
      <a-table
        ref="table"
        rowKey="cart_id"
        :columns="columns"
        :data-source="data"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="false"
      >
        <div slot="goodsname" slot-scope="text, record">
          <div class="flex">
            <a
              :href="'/#/bookBuy/goodsDetail?id=' + record.product_id"
              target="_blank"
            >
              <img
                style="object-fit: contain"
                :src="record.store_image"
                class="img"
              />
            </a>
            <div class="ml16 store_info">
              <span class="line2">{{ record.store_name }}</span>
              <span class="yixuan">已选：{{ record.spec_name }}</span>
            </div>
          </div>
        </div>
        <div slot="unitPrice" slot-scope="text, record">
          <span class="font18">￥{{ record.price }}</span>
        </div>
        <div slot="total" slot-scope="text, record">
          <span class="green2">￥{{ record.goods_price }}</span>
        </div>
        <div slot="num" slot-scope="text, record, index" class="count">
          <div class="count_box">
            <div @click="handleChange(index, 1, record.cart_id)" class="box">
              <a-icon type="minus" />
            </div>
            <a-input-number
              :bordered="false"
              :controls="false"
              :min="1"
              v-model="record.cart_num"
              class="input"
              @change="changeCartNum($event, index, 3, record.cart_id)"
            />
            <div @click="handleChange(index, 2, record.cart_id)" class="box">
              <a-icon type="plus" />
            </div>
          </div>
        </div>
        <div slot="action" slot-scope="text, record">
          <a-icon type="close" @click="close(record.cart_id)" />
        </div>
        <template slot="footer">
          <div class="footer">
            <div>已选 {{ selectedRowKeys.length }} 件，共 {{ total }} 件</div>
            <div>
              <span>合计&nbsp;&nbsp;</span>
              <span class="green">¥{{ totalPrice }}</span>
              <a-button type="primary" @click="toPay">去结算</a-button>
            </div>
          </div>
        </template>
      </a-table>
      <div style="margin-top: 20px; text-align: right">
        <a-pagination
          v-model="current"
          :page-size-options="pageSizeOptions"
          :page-size="pageSize"
          :total="total"
          show-size-changer
          v-if="total != 0"
          @change="onChange"
          @showSizeChange="onShowSizeChange"
        >
        </a-pagination>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import { cartList, cartCount, cartNum, delCart } from "@/api/allGoods";
import { throttle, debounce } from "@/utils/throttle.js";
import { math } from "@/utils/math.js";
const columns = [
  {
    title: "商品名称",
    dataIndex: "goodsname",
    key: "goodsname",
    scopedSlots: { customRender: "goodsname" },
    align: "center",
    width: "30%",
  },
  {
    title: "商品单价",
    dataIndex: "unitPrice",
    key: "unitPrice",
    scopedSlots: { customRender: "unitPrice" },
    align: "center",
    width: "20%",
  },
  {
    title: "商品数量",
    dataIndex: "num",
    key: "address",
    scopedSlots: { customRender: "num" },
    align: "center",
    width: "20%",
  },
  {
    title: "合计",
    dataIndex: "total",
    key: "total",
    scopedSlots: { customRender: "total" },
    align: "center",
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

export default {
  name: "buyCart",
  components: { PageLayout },
  data() {
    return {
      columns,
      data: [],
      // totalPrice: 0,
      selectedRowKeys: [],
      selectedRows: [],
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      current: 1,
      pageSize: 10,
      total: 0,
      changeIndex: 0,
    };
  },
  computed: {
    totalPrice: {
      get() {
        let totalPrice = 0;
        this.selectedRows.forEach((item) => {
          totalPrice = math.add(item.goods_price + totalPrice);
        });
        return totalPrice.toFixed(2);
      },
    },
  },
  created() {},
  mounted() {
    this.getNum();
    this.getList(this.current, this.pageSize);
  },
  watch: {
    data: {
      handler(val) {
        this.selectedRows = val.filter((item) => {
          return this.selectedRowKeys.indexOf(item.cart_id) != -1;
        });
      },
      deep: true,
    },
  },
  methods: {
    toPay() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.error("请选择结算的商品");
        return false;
      }
      this.$router.push({
        path: "submitOrder",
        query: { cartId: this.selectedRowKeys, new: 0 },
      });
    },
    getNum() {
      cartCount().then((res) => {
        this.total = res.data.data.count;
      });
    },
    getList(page, limit) {
      cartList({
        page: page,
        limit: limit,
      }).then((res) => {
        if (res.data.data.list.productInfo) {
          if (res.data.data.list.productInfo.length != 0) {
            this.data = res.data.data.list.productInfo;
            // this.data.forEach(item => {
            //   item.goods_price = item.goods_price.toFixed(2)
            // })
          }
        } else {
          this.data = [];
        }
      });
    },
    changeCartNum: debounce(function (e, list) {
      console.log(e, list);
      let i = list[1];
      this.changeIndex = i;
      let id = list[3];
      if (e == "" || e == null || e < 1) {
        this.$message.warning("商品数量必须大于0");
        this.data[i].cart_num = 1;
      }
      this.handleChange(i, 3, id);
    }, 500),
    handleChange: throttle(function (index, list) {
      let i = list[0];
      let t = list[1];
      let id = list[2];
      if (this.data[i].cart_num > 1) {
        if (t == 1) {
          this.data[i].cart_num = math.subtract(this.data[i].cart_num - 1);
        }
      }
      if (t == 2) {
        this.data[i].cart_num = math.add(this.data[i].cart_num + 1);
      }
      this.data[i].goods_price = math.multiply(
        this.data[i].cart_num * Number(this.data[i].price)
      );
      // this.data[i].goods_price = this.data[i].goods_price.toFixed(2);
      if (this.data[i].cart_num >= 1) {
        cartNum({
          id: id,
          number: this.data[i].cart_num,
        }).then((res) => {
          if (res.data.status == 200) {
            this.getList(this.current, this.pageSize);
            this.getNum();
          } else {
            this.$message.error(res.data.msg);
            this.getList();
          }
        });
      }
    }, 500),
    close(id) {
      this.selectedRows = this.selectedRows.filter(
        (item) => item.cart_id != id
      );
      this.selectedRowKeys = this.selectedRowKeys.filter((item) => item != id);
      delCart({
        ids: [id],
      }).then(() => {
        this.$message.success("删除成功");
        this.getList(this.current, this.pageSize);
        this.getNum();
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    onChange(page, pageSize) {
      this.pageSize = pageSize;
      this.current = page;
      this.getList(page, pageSize);
      this.getNum();
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.current = current;
      this.getList(current, pageSize);
      this.getNum();
    },
  },
};
</script>

<style lang="less" scoped>
@import "buyCart";
</style>
